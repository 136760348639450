@font-face {
  font-family: Times Newer Roman Web;
  src: url("timesnewerroman-regular-webfont.181b4c44.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Times Newer Roman Web;
  src: url("timesnewerroman-italic-webfont.5619be00.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

:root {
  --color--bg: #bbb;
  --color--text: #111;
  --color--accent--1: #7f23ff;
  --color--accent--2: yellow;
  --type--family--1: "Times Newer Roman Web", Times, serif;
  --type--size--1: 14px;
}

html, body {
  min-height: 100%;
  background-color: var(--color--bg);
  margin: 0;
}

html {
  font-family: var(--type--family--1);
  color: var(--color--text);
  font-kerning: normal;
  font-variant: common-ligatures;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: greayscale;
  font-size: 100%;
  line-height: 1.2;
}

::selection {
  color: var(--color--text);
  background-color: var(--color--accent--1);
}

h1 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: auto;
  left: -9999em;
  overflow: hidden;
}

.dialog {
  background-color: var(--color--bg);
  margin: 16px 24px;
}

.dialog a {
  white-space: nowrap;
  color: var(--color--accent--1);
  text-underline-offset: .1em;
  text-decoration-thickness: 1px;
}

.dialog > p {
  letter-spacing: -.01em;
  margin: 0 0 1.2em;
  font-size: 4.5vw;
  line-height: 1.2;
}

@media (max-width: 420px) {
  .dialog {
    margin: 6px 8px;
  }

  .dialog > p {
    font-size: 24px;
    line-height: 1.2;
  }
}

.button {
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color--text);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2em;
  outline: 3px solid rgba(0, 0, 0, 0);
  padding: .1em 1em 0;
  text-decoration: none;
  transition-property: outline;
  transition-duration: .6s;
  display: inline-block;
}

.button:hover, .button:focus-visible {
  outline: 3px solid rgba(0, 0, 0, .2);
  transition-duration: .1s;
}

/*# sourceMappingURL=index.4fc52ea4.css.map */
