@font-face {
    font-family: 'Times Newer Roman Web';
    src: url('../fonts/times-newer-roman/timesnewerroman-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Times Newer Roman Web';
    src: url('../fonts/times-newer-roman/timesnewerroman-italic-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

:root {
    --color--bg: #bbb;
    --color--text: #111;

    --color--accent--1: #7f23ff;
    --color--accent--2: yellow;

    --type--family--1: 'Times Newer Roman Web', Times, serif;
    --type--size--1: 14px;
}

html,
body {
    margin: 0;
    min-height: 100%;

    background-color: var(--color--bg);
}

html {
    font-family: var(--type--family--1);
    font-size: 100%;
    line-height: 1.2;
    color: var(--color--text);

    font-kerning: normal;
    font-variant: common-ligatures;
    text-size-adjust: 100%;

    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: greayscale;
}

body {
}

::selection {
    color: var(--color--text);

    background-color: var(--color--accent--1);
}

h1 {
    position: absolute;
    left: -9999em;
    top: auto;

    width: 1px;
    height: 1px;

    overflow: hidden;
}

.dialog {
    margin: 16px 24px;
    background-color: var(--color--bg);

    & a {
        white-space: nowrap;

        color: var(--color--accent--1);
        text-decoration-thickness: 1px;
        text-underline-offset: 0.1em;
    }

    & > p {
        margin: 0 0 1.2em;

        font-size: 4.5vw;
        line-height: 1.2;
        letter-spacing: -0.01em;
    }

    @media (max-width: 420px) {
        margin: 6px 8px;

        & > p {
            font-size: 24px;
            line-height: 1.2;
        }
    }
}

.button {
    display: inline-block;
    padding: 0.1em 1em 0em;

    text-transform: uppercase;
    text-decoration: none;

    color: #fff;

    background-color: var(--color--text);
    border-radius: 2em;

    cursor: pointer;

    user-select: none;

    outline: 3px solid rgba(0, 0, 0, 0);

    transition-property: outline;
    transition-duration: 600ms;

    &:hover,
    &:focus-visible {
        outline: 3px solid rgba(0, 0, 0, 0.2);

        transition-duration: 100ms;
    }
}
